import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/default.js";
import { Section, SectionHeader } from 'src/components/molecules/Section';
import { Button } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Box } from 'src/components/atoms/Box';
import { Image } from 'src/containers/Image2';
import { SEO } from 'src/containers/SEO';
import { Badge } from 'src/components/atoms/Badge';
import { CommunitySection } from 'src/components/organisms/CommunitySection';
import { Accordion } from 'src/components/molecules/Accordion';
import { Card } from 'src/components/atoms/Card';
import { Teaser } from 'src/components/molecules/Teaser';
import { ProfileImage } from 'src/containers/ProfileImage';
import { Link } from 'src/containers/Link';
import { SaraTrail } from 'src/components/organisms/SaraTrail';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  {
    contributorsTeaserChrisVideoPng: file(relativePath: { eq: "contributors/teaser_chris_video.png" }) {
      ...Image2
    }
    contributorsTeaserMeetupJpg: file(relativePath: { eq: "contributors/teaser_meetup.jpg" }) {
      ...Image2
    }
    contributorsTeaserJulianForumPng: file(relativePath: { eq: "contributors/teaser_julian_forum.png" }) {
      ...Image2
    }
    saraSaraCommunitySvg: file(relativePath: { eq: "sara/sara-community.svg" }) {
      ...Image2NoSharp
    }
    chris: file(relativePath: { eq: "images/profile/community_chris.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 56, width: 56, layout: FIXED)
      }
    }
    yogesh: file(relativePath: { eq: "images/profile/community_yogesh.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 56, width: 56, layout: FIXED)
      }
    }
    julian: file(relativePath: { eq: "images/profile/community_julian.jpg" }) {
      childImageSharp {
        gatsbyImageData(height: 56, width: 56, layout: FIXED)
      }
    }
    memberImages: allFile(
      filter: {
        relativePath: {
          in: [
            "images/profile/1263428.jpeg"
            "images/profile/10572261.jpeg"
            "images/profile/10116000.jpeg"
            "images/profile/3694634.jpeg"
            "images/profile/2381319.jpeg"
            "images/profile/13789524.jpeg"
            "images/profile/1426587.jpeg"
            "images/profile/3694484.png"
            "images/profile/soumya-mukherjee.jpg"
            "images/profile/siddhartha_lahiri.jpg"
            "images/profile/abin_joy.jpg"
            "images/profile/akshay_agarwal.jpg"
            "images/profile/alexis_smirnov.jpg"
            "images/profile/aman_tiwari.jpg"
            "images/profile/andrei_sousa.jpg"
            "images/profile/ankit_sinha.jpg"
            "images/profile/arshid.jpg"
            "images/profile/arthur_temporim.jpg"
            "images/profile/bikash_kumar_sundaray.jpg"
            "images/profile/dmitrii_starov.jpg"
            "images/profile/dominic_breuker.jpg"
            "images/profile/eric_bonfadini.jpg"
            "images/profile/federico_marinelli.jpg"
            "images/profile/fedya_skitsko.jpg"
            "images/profile/feynman_liang.jpg"
            "images/profile/gabriela_barrozo_guedes.jpg"
            "images/profile/greg_stephens.jpg"
            "images/profile/greg_werner.jpg"
            "images/profile/guan_jianyu.jpg"
            "images/profile/guan_wang.jpg"
            "images/profile/jackson_zhipeng_chang.jpg"
            "images/profile/jahir_fiquitiva.jpg"
            "images/profile/jinchao_yu.jpg"
            "images/profile/jonatan_dellagostin.jpg"
            "images/profile/jose_marcos_rodriguez_fernandez_.jpg"
            "images/profile/kevin_castro.jpg"
            "images/profile/luke_jordan.jpg"
            "images/profile/mark_collins.jpg"
            "images/profile/matheus_miranda_lacerda.jpg"
            "images/profile/max_mikhaylov.jpg"
            "images/profile/nahid_alam.jpg"
            "images/profile/nengwe_ntafam_roger.jpg"
            "images/profile/nguyen_dinh_tam.jpg"
            "images/profile/noufal_salim.jpg"
            "images/profile/rahul_prabhakar.jpg"
            "images/profile/rana_mostafa.jpg"
            "images/profile/rania_zyane.jpg"
            "images/profile/ritwik_g.jpg"
            "images/profile/samrudha_kelkar.jpg"
            "images/profile/santiago_gonzalez_toral.jpg"
            "images/profile/shota_makino.jpg"
            "images/profile/sibish_basheer.jpg"
            "images/profile/simon_senkl.jpg"
            "images/profile/sri_yogesh_k.jpg"
            "images/profile/surya_kasturi.jpg"
            "images/profile/ujjwal_puri.jpg"
            "images/profile/utkal_sinha.jpg"
            "images/profile/vishal_jagtap.jpg"
            "images/profile/yifan_zhang.jpg"
          ]
        }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(height: 80, width: 80)
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Contribute to Rasa Open Source" description="Find how you can contribute to Rasa Open Source. Let’s build the best conversational AI Assistants, together." mdxType="SEO" />
    <CommunitySection profileImages={props.data.memberImages.nodes} mdxType="CommunitySection">
      <h2 {...{
        "id": "our-open-source-community-is-growing-rapidly-with-over-750-contributors"
      }}>{`Our open source community is growing rapidly, with over 750 contributors!`}</h2>
      <p>{`We're breaking down the barriers to becoming an open source machine learning contributor.`}</p>
    </CommunitySection>
    <Section secondary centered sloped overlap maxWidth="large" mdxType="Section">
      <SectionHeader mdxType="SectionHeader">
        <h2 {...{
          "id": "meet-some-of-our-contributors"
        }}>{`Meet some of our contributors`}</h2>
      </SectionHeader>
      <Grid columns="1fr 1fr 1fr" spacious centered mdxType="Grid">
  <Teaser to="https://www.youtube.com/watch?v=M08c3fErglo" header={<Image data={props.data.contributorsTeaserChrisVideoPng} alt="A preview of Chris' Videos" noMargin mdxType="Image" />} clipHeader mdxType="Teaser">
    <ProfileImage data={props.data.chris} alt="Chris" centered mdxType="ProfileImage" />
    <center>Baysan created a <u>Rasa installation tutorial video</u> in Turkish.</center>
  </Teaser>
  <Teaser to="/join/" header={<Image data={props.data.contributorsTeaserMeetupJpg} alt="A photo of the attendants in a meetup" noMargin mdxType="Image" />} clipHeader mdxType="Teaser">
    <ProfileImage data={props.data.yogesh} alt="Yogesh" centered mdxType="ProfileImage" />
    <center>Yogesh organises <u>Rasa meetups</u> in Hyderabad, India.</center>
  </Teaser>
  <Teaser to="https://forum.rasa.com/" header={<Image data={props.data.contributorsTeaserJulianForumPng} alt="Julian's profile on our forums" noMargin mdxType="Image" />} clipHeader mdxType="Teaser">
    <ProfileImage data={props.data.julian} alt="Julian" centered mdxType="ProfileImage" />
    <center>Find Julian answering questions on the <u>forum</u>.</center>
  </Teaser>
      </Grid>
    </Section>
    <Section centered mdxType="Section">
      <SectionHeader mdxType="SectionHeader">
        <Image data={props.data.saraSaraCommunitySvg} width="155" alt="Sara watering a plant" centered mdxType="Image" />
        <h2 {...{
          "id": "how-do-i-contribute-to-rasa"
        }}>{`How do I contribute to Rasa?`}</h2>
      </SectionHeader>
      <Accordion sections={[{
        title: 'Like to write code?',
        icon: <Icon icon={['fad', 'code']} size="lg" mdxType="Icon" />,
        content: <>
          <ul>
            <li>Find an open issue on our <a href="https://github.com/orgs/RasaHQ/projects/23 ">Github Dashboard</a></li>
            <li><a href="https://github.com/RasaHQ/rasa/issues/new/choose">Report a bug</a> and help us resolve it</li>
            <li><a href="https://github.com/RasaHQ/rasa/issues/new/choose">Open a Feature Request issue</a> to suggest new code examples for <a href="https://rasa.com/docs">the Rasa docs</a></li>
          </ul>
        </>
      }, {
        title: 'Love to help others?',
        icon: <Icon icon={['fad', 'users']} size="lg" mdxType="Icon" />,
        content: <>
          <ul>
            <li>Answer questions related to <a href="https://github.com/RasaHQ/rasa/pulls">open issues</a></li>
            <li>Answer questions on the <a href="https://forum.rasa.com/">Rasa Community Forum</a></li>
          </ul>
        </>
      }, {
        title: 'Writing more your thing?',
        icon: <Icon icon={['fad', 'pencil']} size="lg" mdxType="Icon" />,
        content: <>
          <ul>
            <li>Suggest edits to the <a href="/docs/">Rasa Docs</a> by clicking the "Suggest Edit" button located at the top of each page</li>
            <li>Create a blog post about Rasa and let us know at <a href="mailto:community@rasa.com">community@rasa.com</a></li>
            <li>Translate the Rasa docs or community content</li>
          </ul>
        </>
      }, {
        title: 'Enjoy planning events?',
        icon: <Icon icon={['fad', 'podium']} size="lg" mdxType="Icon" />,
        content: <>
          <ul>
            <li>Organize an online or offline events</li>
            <li>Organize a meetup, workshop or webinar</li>
            <li>Help someone else organize an event</li>
            <li>To get started, check out our <a href="https://forum.rasa.com/t/guidelines-for-organizing-a-local-community-chapter-in-your-area/3781/2">meetup guidelines</a></li>
          </ul>
        </>
      }, {
        title: 'Prefer to teach or create tutorials?',
        icon: <Icon icon={['fad', 'graduation-cap']} size="lg" mdxType="Icon" />,
        content: <>
          <ul>
            <li>Create a tutorial on Rasa or Rasa Enterprise</li>
            <li>Create a tutorial on how to use your bot contribution</li>
            <li>Support others on creating a tutorial</li>
            <li>Don't forget to share it with us at <a href="mailto:community@rasa.com">community@rasa.com</a></li>
          </ul>
        </>
      }]} mdxType="Accordion" />
    </Section>
    <Section centered mdxType="Section">
      <SectionHeader mdxType="SectionHeader">
        <h2 {...{
          "id": "start-your-contributor-journey"
        }}>{`Start your contributor journey`}</h2>
        <p>{`Community is the core of what we do at Rasa, so we created a space where people of different backgrounds and skills can find ways to get involved. The Rasa Contributor program is designed to get community members inspired and recognize efforts and achievements within the community.`}</p>
      </SectionHeader>
      <SaraTrail mdxType="SaraTrail" />
      <Button to="https://www.rasa.com/blog/announcing-the-rasa-contributor-program/" mdxType="Button">
  Read the blog post to learn more
      </Button>
      <p>{`There are lots of different ways to contribute to open source projects—too many to list here. That’s why we want to know about your contributions even if they weren’t mentioned in the program, and most importantly—recognize them. Tell us how you contribute to the Rasa community by sending us a message at `}<a parentName="p" {...{
          "href": "mailto:community@rasa.com"
        }}>{`community@rasa.com`}</a>{`!`}</p>
      <p><strong parentName="p">{`Still not sure how to contribute, or have further questions? `}<a parentName="strong" {...{
            "href": "mailto:community@rasa.com"
          }}>{`Email Us →`}</a></strong></p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      